<template>
  <TableBase :selectable="selectable">
    <template #header>
      <TableHeaderItem
        class="col-span-6 text-left"
        :class="[selectable ? 'md:col-span-9' : 'md:col-span-11']"
        :text="'Work Area'"
        :firstItem="!selectable"
      />
      <TableHeaderItem class="col-span-2 md:col-span-1" />
    </template>
    <template #body>
      <WorkAreaTableRow
        v-for="workArea in workAreas"
        :key="workArea.id"
        :workArea="workArea"
        :selectable="selectable"
        :selected="selectedIdList.includes(workArea.id)"
        @selected="onSelected"
      />
    </template>
  </TableBase>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import WorkAreaTableRow from "./WorkAreaTableRow.vue";
import TableBase from "@/components/table/TableBase.vue";
import TableHeaderItem from "@/components/table/TableHeaderItem.vue";
// other
import { WorkArea } from "@/graphql/types";

export default defineComponent({
  name: "WorkAreaTable",
  components: {
    WorkAreaTableRow,
    TableBase,
    TableHeaderItem
  },
  props: {
    selectable: { type: Boolean, default: false },
    workAreas: { type: Object as PropType<WorkArea>, required: true },
    selectedIdList: {
      required: false,
      default: () => [] // error if instead "type: Array"
    }
  },
  emits: ["selected"],
  setup(props, context) {
    // pass through event
    const onSelected = (workAreaId: string) => {
      context.emit("selected", workAreaId);
    };

    return { onSelected };
  }
});
</script>
