<template>
  <TableRow :selectable="selectable" :selected="selected" @selected="onClick" class="grid-cols-12">
    <TableRowItem
      class="cursor-pointer"
      :class="[selectable ? 'col-span-6 md:col-span-9' : 'col-span-10 md:col-span-11']"
      :firstItem="!selectable"
      @click="onClick"
    >
      <div class="flex">
        <a class="flex items-center space-x-3">
          <WorkAreaAvatar :text="workArea.abbreviation" :color="workArea.color" :size="'sm'" />
          <span class="truncate hover:text-gray-600">
            {{ workArea.name }}
          </span>
        </a>
      </div>
    </TableRowItem>

    <TableRowItem class="col-span-2 md:col-span-1 flex justify-center"
      ><WorkAreaTableRowMenu :workArea="workArea"
    /></TableRowItem>
  </TableRow>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import WorkAreaTableRowMenu from "./WorkAreaTableRowMenu.vue";
import WorkAreaAvatar from "@/features/workAreas/WorkAreaAvatar.vue";
import TableRow from "@/components/table/TableRow.vue";
import TableRowItem from "@/components/table/TableRowItem.vue";
// other
import { WorkArea } from "@/graphql/types";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaTableRow",
  components: {
    WorkAreaTableRowMenu,
    WorkAreaAvatar,
    TableRow,
    TableRowItem
  },
  props: {
    workArea: { type: Object as PropType<WorkArea>, required: true },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },
  emits: ["selected"],
  setup(props, context) {
    const router = useRouter();

    const onShowDetails = () => {
      const workAreaId = cleanUuid(props.workArea.id);
      router.push({ path: `/work-areas/${workAreaId}/settings` });
    };

    // click name field
    const onClick = () => {
      if (props.selectable) context.emit("selected", props.workArea.id);
      else onShowDetails();
    };

    return { onShowDetails, onClick };
  }
});
</script>
