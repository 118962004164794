
import { defineComponent, PropType } from "vue";
// components
import WorkAreaTableRow from "./WorkAreaTableRow.vue";
import TableBase from "@/components/table/TableBase.vue";
import TableHeaderItem from "@/components/table/TableHeaderItem.vue";
// other
import { WorkArea } from "@/graphql/types";

export default defineComponent({
  name: "WorkAreaTable",
  components: {
    WorkAreaTableRow,
    TableBase,
    TableHeaderItem
  },
  props: {
    selectable: { type: Boolean, default: false },
    workAreas: { type: Object as PropType<WorkArea>, required: true },
    selectedIdList: {
      required: false,
      default: () => [] // error if instead "type: Array"
    }
  },
  emits: ["selected"],
  setup(props, context) {
    // pass through event
    const onSelected = (workAreaId: string) => {
      context.emit("selected", workAreaId);
    };

    return { onSelected };
  }
});
