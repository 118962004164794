
import { defineComponent, PropType } from "vue";
// components
import WorkAreaDeleteButton from "@/features/workAreas/WorkAreaDeleteButton.vue";
import TableRowMenu from "@/components/table/TableRowMenu.vue";
import TableRowMenuItem from "@/components/table/TableRowMenuItem.vue";
// other
import { WorkArea } from "@/graphql/types";
import { cleanUuid } from "@/utils/globalHelpers";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "WorkAreaTableItemMenu",
  components: {
    WorkAreaDeleteButton,
    TableRowMenu,
    TableRowMenuItem
  },
  props: {
    workArea: { type: Object as PropType<WorkArea>, required: true }
  },
  setup(props) {
    // navigation
    const router = useRouter();

    // click edit
    const onEdit = () => {
      const workAreaId = cleanUuid(props.workArea.id);
      router.push({ path: `/work-areas/${workAreaId}/settings` });
    };

    return { onEdit };
  }
});
