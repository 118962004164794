<template>
  <TableRowMenu>
    <TableRowMenuItem :text="'Edit'" @click="onEdit">
      <template #icon>
        <!-- Heroicon name: pencil-alt -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </template>
    </TableRowMenuItem>

    <WorkAreaDeleteButton :workArea="workArea">
      <a
        class="cursor-pointer group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
      >
        <!-- Heroicon name: trash -->

        <svg
          class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        Delete
      </a>
    </WorkAreaDeleteButton>
  </TableRowMenu>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import WorkAreaDeleteButton from "@/features/workAreas/WorkAreaDeleteButton.vue";
import TableRowMenu from "@/components/table/TableRowMenu.vue";
import TableRowMenuItem from "@/components/table/TableRowMenuItem.vue";
// other
import { WorkArea } from "@/graphql/types";
import { cleanUuid } from "@/utils/globalHelpers";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "WorkAreaTableItemMenu",
  components: {
    WorkAreaDeleteButton,
    TableRowMenu,
    TableRowMenuItem
  },
  props: {
    workArea: { type: Object as PropType<WorkArea>, required: true }
  },
  setup(props) {
    // navigation
    const router = useRouter();

    // click edit
    const onEdit = () => {
      const workAreaId = cleanUuid(props.workArea.id);
      router.push({ path: `/work-areas/${workAreaId}/settings` });
    };

    return { onEdit };
  }
});
</script>
